<template>
	<div class="mflswitch" id="mflswitch">
		<!-- 标题部分 -->
		<div class="title-hang">
			<div class="fl">
				<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
			</div>
			<div class="zhuangshizb fl"></div>
			<div class="zhuangshizj fl">{{ projectData.project }}_切换画面</div>
			<div class="zhuangshiyb fl">
				<div class="fl">
					<dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 0vh; margin-left: 16vw" />
				</div>
			</div>
		</div>

		<!-- 下半部分 -->
		<div class="mainall">
			<div class="qxbutton">

			</div>
			<div class="flex">
				<!-- 左边 -->
				<div class="leftmain">
					<div class="leftmain-row1">制粉系统切换</div>
					<div class="leftmain-row2 fl">
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">A给煤机切换</div>
							<div class="fl flexbtn" :style="{background: infoList.IOG.GML_A_XK ? '#29DFAE' : 'red',}"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">B磨给煤切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.GML_B_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">C磨给煤切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.GML_C_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">D磨油压切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.GML_D_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">E磨油压切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.GML_E_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col1">A磨风量切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.RFM_A_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">B磨风量切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.RFM_B_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">C磨风量切换</div>
							<div class="fl flexbtn" :style="{
				        background:infoList.IOG.RFM_C_XK? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">D磨给煤切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.RFM_D_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">E磨给煤切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.RFM_E_XK ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col1">A磨温度切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.LFM_A_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">B磨温度切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.LFM_B_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">C磨温度切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.LFM_C_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">D磨风量切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.LFM_D_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">E磨风量切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.LFM_E_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col1">A磨油压切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.YLF_A_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">B磨油压切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.YLF_B_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">C磨油压切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.YLF_C_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">D磨温度切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.YLF_D_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col1">E磨温度切换</div>
							<div class="fl flexbtn" :style="{
				        background: infoList.IOG.YLF_E_XK_BM ? '#29DFAE' : 'red',
				      }"></div>
						</div>
					</div>
					<div class="fl leftmain-row2 fl">
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">A给煤控制点</div>
							<div class="fl valuex">
								<!-- @click="toCompon(2, 'GML_A_SP', 'IOG', 'GML_A_SP_BM','1#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_A_SP,'GML_A_SP', 'IOG', 'GML_A_SP_BM')"> -->
								{{infoList.IOG.GML_A_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">B给煤控制点</div>
							<div class="fl valuex">
								<!-- @click="toCompon(2, 'GML_B_SP', 'IOG', 'GML_B_SP_BM','2#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_B_SP,'GML_B_SP', 'IOG', 'GML_B_SP_BM')"> -->
								{{infoList.IOG.GML_B_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">C给煤控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'GML_C_SP', 'IOG', 'GML_C_SP_BM','3#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_C_SP,'GML_C_SP', 'IOG', 'GML_C_SP_BM')"> -->
								{{infoList.IOG.GML_C_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">D给煤控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'GML_D_SP', 'IOG', 'GML_D_SP_BM','4#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_D_SP,'GML_D_SP', 'IOG', 'GML_D_SP_BM')">-->
								{{infoList.IOG.GML_D_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">E给煤控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'GML_E_SP', 'IOG', 'GML_E_SP_BM','5#给煤量设定值')"
									@dblclick="Cclick(infoList.IOG.GML_E_SP,'GML_E_SP', 'IOG', 'GML_E_SP_BM')"> -->
								{{infoList.IOG.GML_E_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col2">A磨一次风控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MFL_A_SP', 'IOG', 'MFL_A_SP_BM','1#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_A_SP,'MFL_A_SP', 'IOG', 'MFL_A_SP_BM')"> -->
								{{infoList.IOG.MFL_A_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">B磨一次风控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MFL_B_SP', 'IOG', 'MFL_B_SP_BM','2#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_B_SP,'MFL_B_SP', 'IOG', 'MFL_B_SP_BM')"> -->
								{{infoList.IOG.MFL_B_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">C磨一次风控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MFL_C_SP', 'IOG', 'MFL_C_SP_BM','3#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_C_SP,'MFL_C_SP', 'IOG', 'MFL_C_SP_BM')"> -->
								{{infoList.IOG.MFL_C_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">D磨一次风控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MFL_D_SP', 'IOG', 'MFL_D_SP_BM','4#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_D_SP,'MFL_D_SP', 'IOG', 'MFL_D_SP_BM')"> -->
								{{infoList.IOG.MFL_D_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">E磨一次风控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MFL_E_SP', 'IOG', 'MFL_E_SP_BM','5#磨出口风量SP')"
									@dblclick="Cclick(infoList.IOG.MFL_E_SP,'MFL_E_SP', 'IOG', 'MFL_E_SP_BM')"> -->
								{{infoList.IOG.MFL_E_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col2">A磨出口温度控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'CKT_A_SP', 'IOG', 'CKT_A_SP_BM','1#磨出口温度SP')"
									@dblclick="Cclick(infoList.IOG.CKT_A_SP,'CKT_A_SP', 'IOG', 'CKT_A_SP_BM')"> -->
								{{infoList.IOG.CKT_A_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">B磨出口温度控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'CKT_B_SP', 'IOG', 'CKT_B_SP_BM','2#磨出口温度SP')"
									@dblclick="Cclick(infoList.IOG.CKT_B_SP,'CKT_B_SP', 'IOG', 'CKT_B_SP_BM')"> -->
								{{infoList.IOG.CKT_B_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">C磨出口温度控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'CKT_C_SP', 'IOG', 'CKT_C_SP_BM','3#磨出口温度SP')"
									@dblclick="Cclick(infoList.IOG.CKT_C_SP,'CKT_C_SP', 'IOG', 'CKT_C_SP_BM')"> -->
								{{infoList.IOG.CKT_C_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">D磨出口温度控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'CKT_D_SP', 'IOG', 'CKT_D_SP_BM','4#磨出口温度SP')"
									@dblclick="Cclick(infoList.IOG.CKT_D_SP,'CKT_D_SP', 'IOG', 'CKT_D_SP_BM')"> -->
								{{infoList.IOG.CKT_D_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">E磨出口温度控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'CKT_E_SP', 'IOG', 'CKT_E_SP_BM','5#磨出口温度SP')"
									@dblclick="Cclick(infoList.IOG.CKT_E_SP,'CKT_E_SP', 'IOG', 'CKT_E_SP_BM')"> -->
								{{infoList.IOG.CKT_E_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg leftmain-row2">
							<div class="fl leftmain-col2">A磨油压控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MYP_A_SP', 'IOG', 'MYP_A_SP_BM','A磨油压SP')"
									@dblclick="Cclick(infoList.IOG.MYP_A_SP,'MYP_A_SP', 'IOG', 'MYP_A_SP_BM')"> -->
								{{infoList.IOG.MYP_A_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">B磨油压控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MYP_B_SP', 'IOG', 'MYP_B_SP_BM','B磨油压SP')"
									@dblclick="Cclick(infoList.IOG.MYP_B_SP,'MYP_B_SP', 'IOG', 'MYP_B_SP_BM')"> -->
								{{infoList.IOG.MYP_B_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">C磨油压控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MYP_C_SP', 'IOG', 'MYP_C_SP_BM','C磨油压SP')"
									@dblclick="Cclick(infoList.IOG.MYP_C_SP,'MYP_C_SP', 'IOG', 'MYP_C_SP_BM')"> -->
								{{infoList.IOG.MYP_C_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">D磨油压控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MYP_D_SP', 'IOG', 'MYP_D_SP_BM','D磨油压SP')"
									@dblclick="Cclick(infoList.IOG.MYP_D_SP,'MYP_D_SP', 'IOG', 'MYP_D_SP_BM')"> -->
								{{infoList.IOG.MYP_D_SP}}
							</div>
						</div>
						<div class="leftmain-col1-jg">
							<div class="fl leftmain-col2">E磨油压控制点</div>
							<div class="fl valuex">
								<!--  @click="toCompon(2, 'MYP_E_SP', 'IOG', 'MYP_E_SP_BM','E磨油压SP')"
									@dblclick="Cclick(infoList.IOG.MYP_E_SP,'MYP_E_SP', 'IOG', 'MYP_E_SP_BM')"> -->
								{{infoList.IOG.MYP_E_SP}}
							</div>
						</div>
					</div>
				</div>
				<!-- 中间 -->
				<div class="centermain">
					<!-- 中间上半部分 -->
					<div class="cen-top">
						<div class="leftmain-row1">燃烧系统切换</div>
						<div class="fl leftmain-row2 fl">
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#引风液耦切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.LTP_BA_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#引风液耦切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.LTP_BB_XK? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#送风入口挡板切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.YQO_BA_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#送风入口挡板切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.YQO_BB_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#一次风入口挡板切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.YCF_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#一次风入口挡板切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.YCF_B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col3">A层周界分风切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ECF_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">B层周界分风切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ECF_B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">C层周界分风切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ECF_C_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">D层周界分风切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ECF_D_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">E层周界分风切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ECF_E_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col3">主汽压力控制点</div>
								<div class="fl valuex">
									<!--  @click="toCompon(2, 'ZQP_SP', 'IOG', 'ZQP_SP_BM','主汽压力控制点')"
										@dblclick="Cclick(infoList.IOG.ZQP_SP,'ZQP_SP', 'IOG', 'ZQP_SP_BM')"> -->
									{{infoList.IOG.ZQP_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">炉膛负压控制点</div>
								<div class="fl valuex">
									<!--  @click="toCompon(2, 'LTP_SP', 'IOG', 'LTP_SP_BM','炉膛压力设定值')"
										@dblclick="Cclick(infoList.IOG.LTP_SP,'LTP_SP', 'IOG', 'LTP_SP_BM')"> -->
									{{infoList.IOG.LTP_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">烟气含氧量控制点</div>
								<div class="fl valuex">
									<!--  @click="toCompon(2, 'YQO_SP', 'IOG', 'YQO_SP_BM','烟气氧量设定值')"
										@dblclick="Cclick(infoList.IOG.YQO_SP,'YQO_SP', 'IOG', 'YQO_SP_BM')"> -->
									{{infoList.IOG.YQO_SP}}
								</div>
							</div>
						</div>
						<div class="fl leftmain-row2 fl">
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#引风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__LTP_MAN1.OutT,'1#引风开度上限','OutT','RQM__p__LTP_MAN1')">
									{{infoList.RQM__p__LTP_MAN1.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#引风开度下限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__LTP_MAN1.OutB,'1#引风开度下限','OutB','RQM__p__LTP_MAN1')">
									{{infoList.RQM__p__LTP_MAN1.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#引风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__LTP_MAN2.OutT,'2#引风开度上限','OutT','RQM__p__LTP_MAN2')">
									{{infoList.RQM__p__LTP_MAN2.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#引风开度下限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__LTP_MAN2.OutB,'2#引风开度下限','OutB','RQM__p__LTP_MAN2')">
									{{infoList.RQM__p__LTP_MAN2.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#送风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN1.OutT,'1#送风开度上限','OutT','RQM__p__YQO_MAN1')">
									{{infoList.RQM__p__YQO_MAN1.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#送风开度下限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN1.OutB,'1#送风开度下限','OutB','RQM__p__YQO_MAN1')">
									{{infoList.RQM__p__YQO_MAN1.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#送风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN2.OutT,'2#送风开度上限','OutT','RQM__p__YQO_MAN2')">
									{{infoList.RQM__p__YQO_MAN2.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#送风开度下限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN2.OutB,'2#送风开度下限','OutB','RQM__p__YQO_MAN2')">
									{{infoList.RQM__p__YQO_MAN2.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#一次风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN1.OutT,'1#一次风开度上限','OutT','RQM__p__YQO_MAN1')">
									{{infoList.RQM__p__YQO_MAN1.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">1#一次风开度下限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN1.OutB,'1#一次风开度下限','OutB','RQM__p__YQO_MAN1')">
									{{infoList.RQM__p__YQO_MAN1.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#一次风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN2.OutT,'2#一次风开度上限','OutT','RQM__p__YQO_MAN2')">
									{{infoList.RQM__p__YQO_MAN2.OutT}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">2#一次风开度上限</div>
								<div class="fl valuex"
									@click="toIpt(infoList.RQM__p__YQO_MAN2.OutB,'2#一次风开度上限','OutB','RQM__p__YQO_MAN2')">
									{{infoList.RQM__p__YQO_MAN2.OutB}}
								</div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col3">一次风压控制点</div>
								<div class="fl valuex" @click="toIpt(infoList.IOG.YCF_A_SP,'一次风压控制点','YCF_A_SP','IOG')">
									{{infoList.IOG.YCF_A_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col3">总风量控制点</div>
								<div class="fl valuex">
									<!--  @click="toCompon(2, 'SFF_SP', 'IOG', 'SFF_SP_BM','送风流量设定值')"
										@dblclick="Cclick(infoList.IOG.SFF_SP,'SFF_SP', 'IOG', 'SFF_SP_BM')"> -->
									{{infoList.IOG.SFF_SP}}
								</div>
							</div>

						</div>
					</div>
					<!-- 中间下半部分 -->
					<div class="cen-bottom">
						<div class="leftmain-row1">状态提示</div>
						<div class="leftmain-col1-jg fl kongzhijg">
							<div class="fl leftmain-col1">BCS控制</div>
							<div class="fl flexbtn" :style="{
				        background: 1 ? '#29DFAE' : 'red',
				      }"></div>
						</div>
						<div class="leftmain-col1-jg fl kongzhijg">
							<div class="fl leftmain-col1">DCS控制</div>
							<div class="fl flexbtn" :style="{
				        background:0 ? '#29DFAE' : 'red',
				      }"></div>
						</div>
					</div>
				</div>
				<!-- 右边 -->
				<div class="rightmain">
					<div class="rigttop">
						<div class="leftmain-row1">汽水系统切换</div>
						<div class="fl leftmain-row2">
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">主给水阀门切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.QBW_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">副给水阀门切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.QBW_B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">一级甲减温切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZQT_1A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">一级乙减温切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZQT_1B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">二级甲减温切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZQT_2A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">二级乙减温切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZQT_2B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg ">
								<div class="fl leftmain-col2">再热烟气温度切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZRQT_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">再热蒸汽流量切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.ZRQT_B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col2x">脱硫系统切换</div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2-jg">
								<div class="fl leftmain-col2">脱硫A阀门切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.TL_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>

							<div class="leftmain-col1-jg leftmain-row2-jg">
								<div class="leftmain-col1-jg leftmain-row2-jg">
									<div class="fl leftmain-col2x">脱硝系统切换</div>
								</div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2-jg">
								<div class="fl leftmain-col2 ">脱硝A阀门切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.TX_A_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
							<div class="leftmain-col1-jg ">
								<div class="fl leftmain-col2">脱硝B阀门切换</div>
								<div class="fl flexbtn" :style="{
				          background: infoList.IOG.TX_B_XK ? '#29DFAE' : 'red',
				        }"></div>
							</div>
						</div>
						<div class="fl leftmain-row2 ">
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">汽包水位控制点</div>
								<div class="valuex1 fl">
									<!--  @click="toCompon(2, 'QBW_SP', 'IOG', 'QBW_SP_BM','水位设定值')"
										@dblclick="Cclick(infoList.IOG.QBW_SP,'QBW_SP', 'IOG', 'QBW_SP_BM')"> -->
									{{infoList.IOG.QBW_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2"></div>
								<div class="valuex1 fl">
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">左一级减温控制点</div>
								<div class="valuex1 fl">
									<!--  @click="toCompon(2, 'ZQT_1A_SP', 'IOG', 'ZQT_1A_SP_BM','甲一级减温控制点')"
										@dblclick="Cclick(infoList.IOG.ZQT_1A_SP,'ZQT_1A_SP', 'IOG', 'ZQT_1A_SP_BM')">-->
									{{infoList.IOG.ZQT_1A_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">右一级减温控制点</div>
								<div class="valuex1 fl">
									<!-- @click="toCompon(2, 'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM','乙一级减温控制点')"
										@dblclick="Cclick(infoList.IOG.ZQT_1B_SP,'ZQT_1B_SP', 'IOG', 'ZQT_1B_SP_BM')">-->
									{{infoList.IOG.ZQT_1B_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">左二级减温控制点</div>
								<div class="valuex1 fl">
									<!-- @click="toCompon(2, 'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM','甲二级减温控制点')"
										@dblclick="Cclick(infoList.IOG.ZQT_2A_SP,'ZQT_2A_SP', 'IOG', 'ZQT_2A_SP_BM')">-->
									{{infoList.IOG.ZQT_2A_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">右二级减温控制点</div>
								<div class="valuex1 fl">
									<!-- @click="toCompon(2, 'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM','乙一级减温控制点')"
										@dblclick="Cclick(infoList.IOG.ZQT_2B_SP,'ZQT_2B_SP', 'IOG', 'ZQT_2B_SP_BM')">-->
									{{infoList.IOG.ZQT_2B_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">再热烟气温度控制点</div>
								<div class="valuex1 fl">
									<!-- @click="toCompon(2, 'ZRQT_A_SP', 'IOG', 'ZRQT_A_SP_BM','甲再热汽温设定值')"
										@dblclick="Cclick(infoList.IOG.ZRQT_A_SP,'ZRQT_A_SP', 'IOG', 'ZRQT_A_SP_BM')">-->
									{{infoList.IOG.ZRQT_A_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg">
								<div class="fl leftmain-col2">再热蒸汽流量控制点</div>
								<div class="valuex1 fl">
									<!-- @click="toCompon(2, 'ZRQT_B_SP', 'IOG', 'ZRQT_B_SP_BM','乙再热汽温设定值')"
										@dblclick="Cclick(infoList.IOG.ZRQT_B_SP,'ZRQT_B_SP', 'IOG', 'ZRQT_B_SP_BM')">-->
									{{infoList.IOG.ZRQT_B_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col2x"></div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2-jg">
								<div class="fl leftmain-col1">SO₂控制点</div>
								<div class="fl valuex1">
									<!-- @click="toCompon(2, 'TL_A_SP', 'IOG', 'TL_A_SP_BM','SO2设定值')"
										@dblclick="Cclick(infoList.IOG.TL_A_SP,'TL_A_SP', 'IOG', 'TL_A_SP_BM')">-->
									{{infoList.IOG.TL_A_SP}}
								</div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2">
								<div class="fl leftmain-col2x"></div>
							</div>
							<div class="leftmain-col1-jg leftmain-row2-jg">
								<div class="fl leftmain-col1">NOx控制点</div>
								<div class="fl valuex1">
									<!-- @click="toCompon(2, 'TX_A_SP', 'IOG', 'TX_A_SP_BM','NOX设定值')"
										@dblclick="Cclick(infoList.IOG.TX_A_SP,'TX_A_SP', 'IOG', 'TX_A_SP_BM')">-->
									{{infoList.IOG.TX_A_SP}}
								</div>
							</div>
						</div>
					</div>
					<!-- 最右侧下边 -->
					<div class="rigtbotm">
						<div class="rigtbotm-jg">系统监控</div>
						<div class="row2-1 fl">
							<div class="yuan1">
								<div class="text">DCS1</div>
								<div class="number" @click="toCompon(2, 'HEART_1', 'IOG', 'HEART_1_BM','1#心跳数据')"
									@dblclick="Cclick(infoList.IOG.HEART_1,'HEART_1', 'IOG', 'HEART_1_BM')">
									{{infoList.IOG.HEART_1}}
								</div>
							</div>
						</div>
						<div class="row2-1 fl">
							<div class="yuan1">
								<div class="text">BCS1</div>
								<div class="number" @click="toCompon(2, 'HEART_1R', 'IOG', 'HEART_1R_BM','1#心跳数据返回')"
									@dblclick="Cclick(infoList.IOG.HEART_1R,'HEART_1R', 'IOG', 'HEART_1R_BM')">
									{{infoList.IOG.HEART_1R}}
								</div>
							</div>
						</div>
						<div class="row2-1 fl">
							<div class="yuan1">
								<div class="text">DCS2</div>
								<div class="number" @click="toCompon(2, 'HEART_2', 'IOG', 'HEART_2_BM','2#心跳数据')"
									@dblclick="Cclick(infoList.IOG.HEART_2,'HEART_2', 'IOG', 'HEART_2_BM')">
									{{infoList.IOG.HEART_2}}
								</div>
							</div>
						</div>
						<div class="row2-1 fl">
							<div class="yuan1">
								<div class="text">BCS2</div>
								<div class="number" @click="toCompon(2, 'HEART_2R', 'IOG', 'HEART_2R_BM','2#心跳数据返回')"
									@dblclick="Cclick(infoList.IOG.HEART_2R,'HEART_2R', 'IOG', 'HEART_2R_BM')">
									{{infoList.IOG.HEART_2R}}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		 <Historical v-if="isHshow" @sendStatus="isClose1" :historyname="historyname" :node="node" :Lkname="Lkname"
			:chName="chName" :infoList="infoList"></Historical>
		<inputVal ref="inputVal"></inputVal>
		<JRLcysz v-if="flag == 4 ? (isComShow = true) : (isComShow = false)" @sendStatus="isClose" :infoList="infoList">
		</JRLcysz>
	</div>

</template>
<script>
	import Historical from "@/components/Historical";
	import inputVal from "@/components/inputVal"; //输入框组件
	import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
	export default {
		name: "index",
		components: {
			Historical,
			inputVal,
			JRLcysz,
		},
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {}; // 默认值
				},
			},
		},
		data: () => {
			return {
				chName: '',
				headerStyle: {
					"font-weight": "500",
					"font-size": "1.8vh",
					height: "1vh",
					borderColor: "#0f3747",
					background: "#205365",
					padding: "0px",
					border: "none",
					color: "#5ca4a6",
				},
				flag: 0,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				historyname: "",
				nodename: "",
				Observername: "",
				Observernode: "",
				Rsfname: "",
				Rsfnode: "",
				bodyHeight: "",
				Firstcontrolname: "",
				Firstcontrolnode: "",
				Firstcontroltitname: "",
				fullscreenLoading: true,
				isHshow: false,
				isOshow: false,
				isRshow: false,
				screenWidth: document.body.clientWidth,
				screeHeight: "",
				conHeight: "",
				leftHeight: "",
				leftcon: "",
				tabline: "",
				tabline1: "",
				tabstyle: "",
				tablist: "",
				tabdatawidth: "",
				tabdatawidth1: "",
				Aname: "",
				spotArr: [],
				authInfo: [],
				changValue: null,
				config: {
					data: [10],
					shape: "round",
					colors: ["#43ff44", "#43ff44"],
					waveOpacity: "1",
					waveNum: "50",
					formatter: "{value}",
				},
				rightHeight: "",
			};
		},

		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"));
			this.$bus.$on("sendMsg", (msg, msg1) => {
				// 兄弟组件发送来的消息
				this.flag = msg;
			});
		},

		computed: {},
		mounted() {},
		methods: {
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}
					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			toIpt(data, name, historyname, node) {
				this.$refs.inputVal.open(data, name, historyname, node);
			},
			closeCompon() {
				this.$emit("sendStatus", "Param01", false);
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name,
				};
				this.spotArr.push(spojobj);
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll();
							map.set(item.name, item);
							this.$message.success("已添加");
						} else {
							this.$message.closeAll();
							this.$message.error("已添加到变量池里，切勿重复添加");
						}
					}

					return [...map.values()];
				};
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
			},
			isClose() {
				(this.flag = 3), this.$bus.$emit("footersendMsg", 3);
			},
			isClose1() {
				this.isHshow = false;
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key;
				this.historyname = name;
				this.node = name2;
				this.Lkname = name3;
				switch (key) {
					case 0:
						return (
							(this.isMshow = true),
							(this.Manualname = name),
							(this.Manualnode = name2),
							(this.ManualAname = name3),
							(this.Manualtitname = titname)
						);
					case 1:
						return (
							(this.isFshow = true),
							(this.Firstcontrolname = name),
							(this.Firstcontrolnode = name2),
							(this.Firstcontroltitname = titname)
						);
					case 2:
						return (
							(this.isHshow = true),
							(this.chName = name4)
						);
				}
			},
			// 打开历史趋势窗口
			toHistory(data) {
				this.$refs.history.open(data);
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mflswitch {
		width: 96vw;
		height: 100%;
		//  background: #e9f7ff;
		background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
			linear-gradient(#0e3449, #0e3449);
		background-blend-mode: normal, normal;
		overflow: hidden;
		//  cursor:move;
		border-radius: 5px;

		.ziti {
			font-size: 0.9vw;
		}

		.zhuangshi1 {
			width: 30vw;
			height: 10vh;
			background-color: #0ef7ff;
			opacity: 0.66;
		}

		.fl {
			float: left;
		}

		.fr {
			float: right;
		}

		.title-hang {
			height: 5vh;
			width: 96vw;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter1 {
			margin-left: 1vw;
			width: 17vw;
			height: 29vh;
			line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate01.png");
			background-size: 100% 100%;
		}

		.conter2 {
			margin-left: 1vw;
			width: 29vw;
			height: 29vh;
			// line-height: 29vh;
			background-image: url("~@/assets/images/rfl_evaluate03.png");
			background-size: 100% 100%;
		}

		.title1 {
			width: 8vw;
			height: 2vh;
			font-family: PingFang-SC-Regular;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #ffffff;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.divcei {
			margin-top: 3vh;
			margin-left: 4vw;
			width: 9vw;
			height: 20vh;
			line-height: 20vh;
			background-image: url("~@/assets/images/rfl_evaluate02.png");
			background-size: 100% 100%;
			font-size: 1vw;
			font-weight: bold;
			font-stretch: normal;
			letter-spacing: 0vw;
			color: #ffffff;
			text-align: center;
		}

		.zhuangshizb {
			width: 14vw;
			height: 3vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-left: 3.5vw;
			margin-top: 1.6vh;
		}

		.zhuangshizj {
			width: 26vw;
			height: 5vh;
			line-height: 5vh;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			font-family: MicrosoftYaHei;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vh;
			color: #0ef7ff;
		}

		.zhuangshiyb {
			width: 14vw;
			height: 3vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 5vw;
			margin-top: 1.6vh;
		}

		.title-hang {
			width: 100%;
		}

		.mainall{
		  margin-left: 1vw;
		}
		
		.leftmain {
			width: 28vw;
			height: 74vh;
			
			//  border: 1px salmon solid;
			background-image: url("~@/assets/images/jrl-evaluate.png");
			background-size: 100% 100%;
			font-family: PingFang-SC-Regular;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;
			margin-top: 1vh;
		}

		.centermain {
			font-size: 1vw;
		}

		.leftmain-row1 {
			margin-left: 2vw;
			margin-top: 0.5vh;
			color: #ffffff;
		}

		.qxbutton {
			width: 7vw;
			height: 4vh;
			// background-color: #00ff8a20;
			// border: solid 1px #00fc98;
			// color: #0de0ac;
			color: #fff;
			text-align: center;
			line-height: 2vh;
		}

		.cen-top {
			width: 37vw;
			height: 52vh;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.cen-bottom {
			width: 37vw;
			height: 21vh;
			background-image: url("~@/assets/images/jrl-evaluate5.png");
			background-size: 100% 100%;
			margin-top: 1vh;
			margin-left: 1vw;
		}

		.cencol {
			width: 9vw;
			height: 7vh;
			background-image: url("~@/assets/images/page_img2.png");
			background-size: 100% 100%;
			margin-left: 2vw;
			margin-top: 2vh;
			padding-left: 1vw;
		}

		.rigtbotm-jg {
			margin-top: -7vh;
			color: #fff;
		}

		.rigttop {
			width: 29vw;
			height: 52vh;
			background-image: url("~@/assets/images/jrl-evaluate2.png");
			background-size: 100% 100%;
		}

		.row2-3 {
			margin-left: auto;

			.top-flex3-two-stuats {
				width: 8vw;
				height: 4vh;
				text-align: center;
				line-height: 4vh;
			}
		}

		.leftmain-row2 {
			margin-top: 2vh;
			// margin-left: -1vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-col1 {
			width: 8vw;
			height: 2vh;
			line-height: 2vh;
			text-align: right;
			padding-right: 1vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-col1x {
			width: 8vw;
			height: 2vh;
			line-height: 2vh;
			text-align: right;
			padding-right: 1vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-col2 {
			width: 9.8vw;
			height: 2vh;
			line-height: 2vh;
			text-align: right;
			padding-right: 1vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-row2-jg {
			margin-top: 1vh;

		}

		.leftmain-col2x {
			width: 10vw;
			height: 2vh;
			line-height: 2vh;
			text-align: right;
			padding-right: 1vw;
			color: #fff;
			font-size: 1.2vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-col3 {
			width: 11vw;
			height: 2vh;
			line-height: 2vh;
			text-align: right;
			padding-right: 1vw;
			padding-left: 1vw;
			// border:1px saddlebrown solid;
		}

		.leftmain-col1-jg {
			height: 3vh;
			line-height: 3vh;
			margin-left: -0.5vw;
		}

		.flexbtn {
			width: 4vw;
			height: 2vh;
			line-height: 2vh;
			// margin-left:1vw;
		}

		.valuex {
			width: 4vw;
			height: 2vh;
			line-height: 2vh;
			color: #00ffb4;
			cursor: pointer;
		}

		.valuex1 {
			width: 3vw;
			height: 2vh;
			line-height: 2vh;
			color: #00ffb4;
			cursor: pointer;
		}

		.mainall {
			color: #5ea7ba;
		}

		.kongzhijg {
			margin-top: 8vh;
			margin-left: 2vw;
		}

		.rigtbotm {
			padding-top: 7vh;
			padding-left: 2vw;
			width: 26vw;
			height: 14vh;
			margin-top: 1vh;
			background-image: url("~@/assets/images/jrl-evaluate5.png");
			background-size: 100% 100%;
		}

		.row2-1 {
			margin-left: 1vw;
			margin-top: 1vh;
			width: 9vh;
			height: 9vh;
			border-radius: 9vh;
			border: 0.25vw solid rgba(54, 90, 161, 0.3);
			cursor: pointer;

			.yuan1 {
				width: 8vh;
				height: 8vh;
				border-radius: 8vh;
				border: 0.3vw solid rgba(51, 255, 204, 0.5);
				text-align: center;

				.text {
					font-size: 1vw;
					color: #6acafb;
					border-bottom: 1px solid #6acafb;
					padding-top: 0.3vh;
				}

				.number {
					color: #ffffff;
					font-size: 0.9vw;
				}
			}
		}

		.rightmain {
			margin-left: 1vw;
			font-size: 1vw;
			// color: #6acafb;
		}
	}
</style>
